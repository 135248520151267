h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  text-transform: uppercase;
  letter-spacing: 1px;
}

// helper classes
.text-lt-blue { color: $brand-lt-blue;}
.text-lt-blue { color: $brand-lt-blue;}
.text-lt-blue { color: $brand-lt-blue;}
.bg-lt-blue { background-color: $brand-lt-blue;}

.btn-secondary {
  &:hover {
    color: $secondary;
    background-color: $primary;
  }
}

.fui-btn.fui-submit {
  font-weight: 700;
  background-color: $secondary!important;
  color:$primary!important;
  &:hover {
    background-color: $primary!important;
    color:$secondary!important;
  }
}

// images
img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}
.object-fit-bg {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
  z-index: 1;
  object-position: center center;
}
.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: filter .2s ease-in, -webkit-filter .2s ease-in;
  &.lazyloaded {
    filter: blur(0);
  }
}

// header
#global-site-header {
  position: relative;
  z-index: 1001;
  &.fixed-top {
    position: fixed;
  }
  .header-logo-link {
    position: relative;
    transition: all .2s ease;
    &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid transparent;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      transition: all .2s ease;
    }
    &:hover {
      background-color: darken($primary,5);
      &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid darken($primary,5);
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &:focus {
      background-color: darken($primary,5);
      &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid darken($primary,5);
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .site-header-logo {
    width: 200px;
    height: 20px;
  }
}

// home navigation
.home-header-navbar{
  .nav-link {
    position: relative;
    font-family: $headings-font-family;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $secondary;
    font-size: 1.25rem;
    transition: all .2s ease;
    padding-left: 1rem;
    padding-right: 1rem;

    &:hover {
      color: $primary;
      background: $secondary;
    }
    &:focus {
      color: $primary;
      background: $secondary;
    }
    @include media-breakpoint-up(lg) {
      &.active {
        color: $primary;
        background: $secondary;
        &:after {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $secondary;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        transition: all .2s ease;
      }
      &:hover {
        &:after {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $secondary;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:focus {
        &:after {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid $secondary;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
.navbar-toggler {
  .toggle-svg {
    width: 20px;
    height: 20px;
    fill: $white;
  }
  &:hover {
    .toggle-svg {
      fill: $secondary;
    }
  }
  &:focus {
    outline: 2px solid $secondary;
  }
  &[aria-expanded='false'] {
    .close-svg {
      display: none;
    }
  }
  &[aria-expanded='true'] {
    .open-svg {
      display: none;
    }
  }
}

// footer
.site-footer {
  background-color: $gray-300;
  .footer-svg {
    width: 300px;
    height: 28px;
  }
  .social-icons {
    background-color:$brand-lt-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 9999rem;
    transition: background-color 0.2s ease;
    svg {
      fill: $white;
      width: 18px;
      height: 18px;
    }
    &:hover {
      background-color: $primary;
    }
    &:focus {
      background-color: $primary;
    }
  }
}

// imgix luminous
.lum-lightbox {
  z-index: 1002;
}
.lum-close-button {
  background: rgba(0,0,0,.5);
  border-radius: .25rem;
  opacity: 1;
  height: 45px;
  width: 45px;
  &:before, &:after {
    left: 21px;
    width: 3px;
    top: 7px;
  }
}
.lum-gallery-button {
  border-radius: .25rem;
  background: rgba(0,0,0,.5);
  height: 45px;
  width: 45px;
  &:after {
    width: 20px;
    height: 20px;
  }
}
.lum-previous-button:after {
  left: 35%;
}
.lum-next-button:after {
  right: 35%;
}
.lum-lightbox-inner img {
  @media screen and (max-width: 460px) {
    max-width: 100%;
    max-height: 100%;
  }
}