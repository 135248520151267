$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  xmd: 992px,
  lg: 1200px,
  xl: 1440px,
  xxl: 1600px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  xmd: 960px,
  lg: 1140px,
  xl: 1320px,
  xxl: 1560px,
);

// typography
$enable-responsive-font-sizes: true;
$headings-font-family: alfarn, sans-serif;
$headings-font-weight: 400;

// colors
$primary: #002b53;
$secondary: #f4cf01;
$brand-lt-blue: #30b3db;
$headings-color: $primary;
$link-color: darken($brand-lt-blue, 10);

// navbar
$navbar-padding-x: 0;
$navbar-padding-y: 0;
$navbar-nav-link-padding-x: 1rem;
$nav-link-padding-y: 1rem;
$nav-link-padding-x: 1rem;
$navbar-toggler-padding-y: .75rem;

$card-border-radius: 1rem;