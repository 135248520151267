// containers
html {
  scroll-behavior: smooth;
}
.inner-max-width {
  position: relative;
  max-width: 48rem;
  width: 100%;
  z-index: 2;
  @include media-breakpoint-up(xmd) {
    max-width: 60rem;
  }
}
.row-min-height {
  @include media-breakpoint-up(xmd) {
    min-height: 30rem;
  }
}
.row-min-height-narrow {
  height: 40vh;
  min-height: 24rem;
}
.row-home-min-height {
  min-height: 20rem;
  @include media-breakpoint-up(xmd) {
    min-height: 24rem;
  }
}

// bg images
.hero-bg-img {
  position: absolute;
  top: 0;
  left: 1rem;
  right: 0;
  width: calc(100% - 2rem);
  height: 60vh;
  min-height: 36rem;
  &:after {
    content: '';
    position: absolute;
    background: linear-gradient(to top,rgba($primary,1),rgba($primary,.25),rgba($primary,0));
    bottom: 0;
    left: 0;
    height: 40vh;
    width: 100%;
    z-index: 1;
  }
  @include media-breakpoint-up(xmd) {
    left: 3rem;
    right: 0;
    width: calc(100% - 6rem);
  }
}

// home
.board-member-table {
  @include media-breakpoint-down(md) {
    td {
      display: block;
    }
    > tbody > tr > td {
      &:not(:first-of-type) {
        border-top: 0;
      }
    }
  }
}
.stay-informed-card {
  -webkit-box-shadow: 0 15px 20px -15px rgba(darken($primary,10),.75);
  box-shadow: 0 25px 30px -15px rgba(darken($primary,10),.75);
}
.home-hero-copy {
  h1, h2, h3 ,h4 {
    color: $white;
  }
}
.updates-icon {
  width: 125px;
  height: 100px;
}
.top-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $secondary;
  svg {
    transform: translateY(0px);
    transition: transform .15s linear;
  }
  .contact-member-icon {
    width: 100px;
    height: 100px;
  }
  .in-news-icon {
    width: 100px;
    height: 100px;
  }
  .community-icon {
    width: 100px;
    height: 100px;
  }
  &:hover {
    color: $secondary;
    svg {
      transform: translateY(-7px);
    }
  }
}

// page header
.page-title {
  color: $white;
}

// articles
.more-articles-link {
  text-decoration: none;
  border: 2px solid $border-color;
  &:hover {
    border-color: $brand-lt-blue;
    text-decoration: none;
  }
  &:focus {
    border-color: $primary;
  }
}

.share-links a {
  background-color:$gray-300;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 9999rem;
  transition: background-color 0.2s ease;
  svg {
    fill: $primary;
    width: 18px;
    height: 18px;
    transition: fill 0.2s ease;
  }
  &:hover {
    svg {
      fill: $white;
    }
    background-color: $primary;
  }
  &:focus {
    svg {
      fill: $white;
    }
    background-color: $primary;
  }
}
//.social-share-icon-facebook svg {
//  width: 12px;
//  height: 20px;
//}
//.social-share-icon-twitter svg {
//  width: 20px;
//  height: 20px;
//}
//.social-share-icon-email svg {
//  width: 20px;
//  height: 20px;
//}

// content blocks
.column-img {
  width: 1024px;
  max-width: 100%;
  @include media-breakpoint-up(xmd) {
    max-width: none;
  }
}
[class*="bg-overlay-"] {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  right:0;
  height: 100%;
  z-index: 2;
}
.bg-overlay-primary {
  background: linear-gradient(to top left, rgba($primary,1), rgba($primary,0));
}
.bg-overlay-secondary {
  background: linear-gradient(to top left, rgba($secondary,1), rgba($secondary,0));
}
.bg-overlay-lt-blue {
  background: linear-gradient(to top left, rgba($brand-lt-blue,1), rgba($brand-lt-blue,0));
}

.section-bg-img {
  position: relative;
  padding-bottom: 62.5%;
  @include media-breakpoint-up(xmd) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    padding-bottom: 0;
    &.section-bg-half {
      width: 50%;
      left: auto;
    }
  }
}
.together-section {
  .section-bg-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    padding-bottom: 0;
    &.section-bg-half {
      width: 50%;
      left: auto;
    }
  }
}
.cb-listModule {
  .list-module-copy {
    ul {
      > li {
        @extend .mb-2;
      }
      @extend .pl-3;
      list-style: disc;
    }
    img {
      margin:0 auto;
      display:block;
    }
  }
}
.cb-testimonial {
  .qsi-testimonial-img {
    width: 325px;
    height: 325px;
  }
}
figcaption {
  margin-top: 0.5rem;
  @extend .figure-caption;
}
.cb-copy,
.cb-accordion {
  .copy-figure-left,
  .copy-figure-right {
    @extend .my-3;
    @include media-breakpoint-up(md) {
      width: 40%;
    }
  }
  .copy-figure-left {
    @include media-breakpoint-up(md) {
      float: left;
      margin-right: 2rem;
    }
  }
  .copy-figure-right {
    @include media-breakpoint-up(md) {
      float: right;
      margin-left: 2rem;
    }
  }
}

// florish chart
.y-axis-label {
  left: -60px;
  top: 50%;
  transform-origin: center;
  transform: translateY(-50%) rotate(-90deg);
  @include media-breakpoint-up(md) {
    left: -40px;
  }
}